.c-radio-block {
  &__single {
    padding: 2rem;
    border-radius: 1rem;
    border: 2px solid #EEEEEE;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__text {
      display: flex;
      align-items: center;
      input {
        margin-right: 1rem;
      }
      div {
        display: flex;
        flex-direction: column;

        h2,h3,h4,h5,h6 {
          font-size: 2rem;
          margin: 0;
        }
        p {
          font-size: 1.4rem;
        }
      }
    }
    &__cta {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 5rem;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        margin-top: 2rem;
        align-items: flex-start;
      }

      span {
        font-weight: 600;
        font-size: 2.4rem;
        margin: 0;
        line-height: 1;
      }
      small {
        margin: 0;
        white-space: nowrap;
      }
    }
  }
  .isActive {
    border: 2px solid black;
  }
}
