.c-bundle {
  &__info {
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }
  &__map {
    width: 30rem;
    min-width: 30rem;
    height: 30rem;
    min-height: 30rem;
    background-color: lightblue;
    border-radius: .6rem;
    margin-right: 2rem;

    @media screen and (max-width: 900px) {
      width: 100%;
      min-width: 100%;
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }
  &__wrap {
    width: 100%;
  }
  &__single {
    margin-bottom: 1rem;

    .c-accordion {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: none;
    }

    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem 2rem;
      border: 1px solid $gray-new;
      border-radius: .6rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      @media screen and (max-width: 1023px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      margin-right: 2rem;
      &__primary {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
      }
      &__secondary {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media screen and (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .c-plus-minus {
          display: none;

          @media screen and (max-width: 600px) {
            margin-top: 1rem;
            order: 2;
          }
        }
      }
      &__text {
        div {
          img {
            margin-right: .5rem;
            width: 1.25rem;
          }
          span {
            font-size: 1.6rem;
          }
        }
      }
      &__number {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        .c-tag {
          margin-right: .5rem;
        }
      }
      &__details {
        @media screen and (max-width: 500px) {
          display: flex;
          flex-direction: column;
          margin-top: 1rem;
        }
        a {
          margin-right: 1rem;
          text-decoration: underline;
          font-size: 1.6rem;
        }
        span {
          margin-right: 1rem;
          font-size: 1.6rem;
          strong {

          }
        }
      }
    }
    &__cta {
      @media screen and (max-width: 1023px) {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 1rem;
      }
      a, button {
        margin: .5rem 0;

        @media screen and (max-width: 1023px) {
          margin: 0 1rem 0 0;
        }
      }
    }

    &--bundle {
      .c-bundle__single__wrap {
        border-radius: .6rem !important;
      }
    }
  }
  .isSelected {
    .c-bundle__single__wrap, .c-accordion {
     border-color: $clr-primary;
    }
    .c-tasks__single__select {
      margin-left: 1rem;
      div {
        border: 2px solid $clr-primary;
        i {
          opacity: 1;
        }
      }
      small {
        display: none;
      }
    }
    .c-plus-minus {
      display: flex;
      margin-right: 2rem;
    }
  }
}

.selectTasks {
  .c-bundle__single__info__primary {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 950px) {
      flex-direction: column;
    }

    .c-tasks__single__select {
      margin-right: 2rem;

      @media screen and (max-width: 950px) {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        width: auto;
        margin: 0 0 1rem;
        padding: 0;

        small {
          margin-left: .5rem;
        }
      }
    }
  }
  .c-bundle__single__cta {
    display: none;
  }
}
