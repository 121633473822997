.c-loading-scan {
  @include square-dimensions(60px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: 650px) {
    @include square-dimensions(40px);
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  &__qr {
    @media screen and (max-width: 650px) {
      width: 20px;
      height: 20px;
    }
  }

  &__loader {
    display: block;
    text-align: center;
    transform: translateX(-30px);

    @media screen and (max-width: 650px) {
      width: 80px;
      height: 80px;
      transform: translateX(-20px);
    }

    path {
      fill: $clr-primary;
    }
  }
}
