/*------------------------------------*\
  #Form
\*------------------------------------*/

.c-lang-selector {
  display: flex;
  align-items: center;
  position: relative;

  img {
    position: absolute;
    top: 1.3rem;
    left: 1.3rem;

    @media screen and (max-width: 520px) {
      top: 1.1rem;
      left: 1.2rem;
    }
  }
  i {
    position: absolute;
    top: 2rem;
    right: 1.4rem;
    font-size: 0.8rem;
  }
  select {
    padding: .5rem 4.5rem .5rem 4rem;
    -webkit-appearance: none;
    appearance: none;
    -ms-progress-appearance: none;
    -moz-appearance: none;
    border: 1px solid;
    border-radius: 1rem;
    height: 4.5rem;
    font-size: 1.5rem;

    @media screen and (max-width: 1023px) {
      width: 55px;
      max-width: 55px;
      max-height: 4.5rem;
      padding: 0 3.5rem;
      color: transparent !important;
    }

  }
}

.c-customer-info {
  margin-top: 2rem;
}

.c-form {

  &__select {
    display: flex;
    align-items: center;
    position: relative;

    i {
      position: absolute;
      top: 2rem;
      right: 1.4rem;
      font-size: 0.8rem;
    }
    select {
      padding: .5rem 4.5rem .5rem 1.8rem;
      -webkit-appearance: none;
      appearance: none;
      -ms-progress-appearance: none;
      -moz-appearance: none;
      border: 1px solid;
      border-radius: 1rem;
      height: 4.5rem;
      font-size: 1.5rem;
      width: 100%;
    }
  }

  &__row {
    display: flex;
    margin-bottom: 2rem;

    @media screen and (max-width: 750px) {
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    select {
      outline: none;
    }

    .s-search {
      margin-bottom: 0;
    }

    input[type=email], input[type=number], input[type=text], input[type=date], input[type=password], textarea {
      padding: .5rem 1.8rem;
      -webkit-appearance: none;
      appearance: none;
      -ms-progress-appearance: none;
      border: none;
      -moz-appearance: none;
      border-radius: 1rem;
      min-width: 10rem;
      height: 4.5rem;
      font-size: 1.7rem;
      outline: none;
    }

    textarea {
      resize: vertical;
      min-height: 100px;
      max-height: 200px;
    }

    input[type=radio] {
      width: auto;
    }

    input, select, textarea, .c-form__select {
      width: 100%;
    }

    &--quarter {
      .c-form__group {
        width: 25%;
        margin-right: 2rem;

        @media screen and (max-width: 750px) {
          width: 100%;
          margin-right: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &__group {
    width: 100%;
    margin-right: 2rem;

    @media screen and (max-width: 750px) {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    label {
      font-size: 1.6rem;
      font-weight: 600;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &--row {
      display: flex;
      align-items: center;
    }

    &--small {
      width: auto;

      label {
        white-space: nowrap;
        margin-right: 1rem;
      }
    }

    &--radio {
      display: flex;
      label {
        margin-right: 2rem;
        display: flex;
        align-items: center;

        span {
          font-size: 1.7rem;
          font-weight: 500;
          margin-left: .5rem;
        }
      }
    }

    &--radio-label {
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &--small {
      max-width: 20%;
      min-width: 20%;

      @media screen and (max-width: 750px) {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    span {
      font-size: 1.7rem;
      font-weight: 500;
      margin-left: .5rem;
    }
  }

  &__pin {
    position: relative;

    i {
      position: absolute;
      top: 1.25rem;
      left: 1rem;
      font-size: 1.8rem;
    }
    input {
      font-size: 4rem !important;
      letter-spacing: 5px;
      padding: 0 1.8rem 0 4rem !important;
    }
  }

  &__border {
    border: 1px solid $clr-secondary !important;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a, button {
      margin-right: 1rem;
    }
  }
}
