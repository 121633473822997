.c-tasks {
  &__quick-bundle {
    margin: 1.5rem 0;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
  }
  &__single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: 1px solid $clr-secondary;
    border-radius: .5rem;
    margin-bottom: 1rem;
    position: relative;

    @media screen and (max-width: 750px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &__info {
      display: flex;
      flex-direction: column;
      margin-right: 2rem;
    }
    &__small {
      display: flex;
      align-items: center;
      @media screen and (max-width: 850px) {
        flex-direction: column;
        align-items: flex-start;
      }
      a {
        font-size: 1.5rem;
        text-decoration: underline;
        margin-right: 1.5rem;
      }
      span {
        margin-right: 1.5rem;
        font-size: 1.5rem;
        strong {
          font-weight: 600;
        }
      }
    }
    &__large {
      display: flex;
      align-items: center;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: .5rem;
      }

      div {
        margin-right: 1.5rem;
        img {

        }
        span {
          font-size: 1.6rem;
        }
      }
    }
    &__cta {
      display: flex;
      align-items: center;

      @media screen and (max-width: 750px) {
        margin-top: 1rem;
      }

      a,button {
        margin-left: .5rem;
      }
      .btn--outline {
        span {
          color: #000000 !important;
        }
      }
      &__expected {
        font-size: 1.3rem;
        margin-right: 1rem;
        display: none;
      }
    }
    &__select {
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      margin-right: 1.5rem;
      width: 70px;

      div {
        @include square-dimensions(2.5rem);
        border-radius: 50%;
        border: 1px solid $clr-secondary;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          opacity: .3;
          font-size: .9rem;
          line-height: 1;

          &:before {
            color: $clr-primary;
          }
        }
      }
      small {
        font-size: 1.2rem;
      }
    }
    .c-task__notification{
      position: absolute;
      top: 2rem;
      left: -3rem;
      @include square-dimensions(3rem);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $red-primary;
      border-radius: .3rem;
      display: none;
      i {
        font-size: 1.4rem;
        &:before {
          color: white;
        }
      }
    }
  }
  .lateTask {
    border: 1px solid $red-primary;
    .c-task__notification{
      display: flex;
    }
  }
  .isSelected {
    border: 1px solid $clr-primary;

    .c-tasks__single__select {
      small {
        display: none;
      }
      div {
        border: 1px solid $clr-primary;
        i {
          opacity: 1;
        }
      }
    }
  }
  .expectedTask {
    background-color: lighten($gray-new, 4%);
    .c-tasks__single__cta__expected {
      display: flex;
    }
  }
}

.selectTasks {
  .c-tasks__single {
    justify-content: flex-start;
    &:hover {
      border: 1px solid $clr-primary;
      cursor: pointer;
    }
    &__select {
      display: flex;

      @media screen and (max-width: 750px) {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        padding: 0;

        small {
          margin-left: 1rem;
        }
      }
    }
    &__cta {
      display: none;
    }
  }
}
