.c-products {
  display: flex;
  flex-wrap: wrap;
  &__single {
    display: flex;
    flex-direction: column;
    width: 28.5rem;
    min-width: 28.5rem;
    border-radius: 1.8rem;
    margin: 0 2.1rem 2.1rem 0;

    &:nth-of-type(3n+3) {
      margin: 0 0 2.1rem;

      @media screen and (max-width: 1023px) {
        margin: 0 1rem 1rem 0;
      }
    }

    @media screen and (max-width: 1350px) {
      width: 33.97rem;
      min-width: 33.97rem;
    }

    @media screen and (max-width: 1023px) {
      width: calc(50% - .5rem);
      min-width: calc(50% - .5rem);
      margin: 0 1rem 1rem 0;

      &:nth-of-type(even) {
        margin: 0 0 1rem 0;
      }
    }

    @media screen and (max-width: 650px) {
      width: 100%;
      min-width: 100%;
      margin: 0 0 1rem 0;

      &:nth-of-type(even) {
        margin: 0 0 1rem 0;
      }

      &:nth-of-type(3n+3) {
        margin: 0 0 1rem 0;
      }
    }


    &__price {
      font-size: 1.8rem;
      font-weight: 700;
      small {
        font-weight: 400;
        font-size: 1.2rem;
      }
    }

    &__info {
      display: flex;
      padding: 1.9rem 1.9rem 1.4rem;

      &__primary {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        order: 2;
        width: 100%;

        h4 {
          font-size: 1.7rem;
          line-height: 1.1;
          margin: 0;
          min-height: 7rem;
        }

        &__wrapper {

        }
      }
      &__secondary {
        display: flex;
        flex-direction: column;
        order: 1;
        margin-right: 1.4rem;
      }
    }
    &__media {
      @include square-dimensions(10.3rem);
      border-radius: .8rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        max-height: 10rem;
        max-width: 10rem;
      }
    }

    &__amount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .7rem;

      @media screen and (max-width: 1350px) {
        justify-content: flex-start;
      }

      .minus {
        i {
          font-size: 5px;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        @include square-dimensions(3.5rem);
        border: none;
        border-radius: .5rem;

        i {
          font-size: 14px;
        }
      }

      span {
        font-size: 2rem;

        @media screen and (max-width: 1350px) {
          margin: 0 2rem;
        }
      }
    }

    &__modal-trigger {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      width: 100%;
      border-radius: .6rem;
      padding: .2rem;

      i {
        font-size: 1.2rem;
        margin-right: .5rem;
      }
      span {
        font-size: 1.2rem;
      }
    }
    &__locations {
      &__single {
        padding: 1rem 1.9rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__wrapper {
          display: flex;
          align-items: center;
        }

        button {
          margin-right: 1.2rem;
          @include square-dimensions(3rem);
          border-radius: .5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;

          i {
            font-size: 1.4rem;
          }
        }
        &__name {
          display: flex;
          flex-direction: column;

          span {
            font-size: 1.3rem;
            font-weight: 700;
            line-height: 1;
          }
          small {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1;
          }
        }
        &__amount {
          display: flex;
          align-items: center;
          i {
            font-size: 1.4rem;
            margin-right: .5rem;
          }
          small {
            font-size: 1.3rem;
            font-weight: 700;
          }
        }
      }
    }

    &--small {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin: 0 1rem 1rem 0;
      width: calc(50% - 1rem);
      max-width: calc(50% - 1rem);
      min-width: calc(50% - 1rem);
      padding: 1.25rem 1.5rem;

      @media screen and (max-width: 900px){
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin: 0 0 1rem 0;
      }

      &:nth-of-type(3n+3) {
        margin: 0 1rem 1rem 0;

        @media screen and (max-width: 900px){
          margin: 0 0 1rem 0;
        }
      }

      .c-products__single__primary {
        display: flex;
        align-items: center;
        margin-right: 2rem;
        h4 {
          margin: 0 2rem 0 0;
          font-size: 1.5rem;
          font-weight: 400;
        }
      }
      .c-products__single__amount {
        margin: 0;

        span {
          margin: 0 1rem;
          @media screen and (max-width: 1350px) {
            margin: 0 1rem;
          }
        }
      }
      .c-products__single__price {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1;
        margin-right: 1.5rem;
      }
      .c-products__single__supply {
        border-radius: .5rem;
        margin-right: 1.5rem;
        background-color: $secondary-color;
        padding: .5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $color-black;
        font-size: 1.6rem;
        font-weight: 600;
      }
      .c-products__single__secondary {
        display: flex;
        align-items: center;
      }
    }

    &--inactive {
      .c-products__single__amount {
        pointer-events: none;
        opacity: .25;
      }
      .c-products__single__locations__single {
        opacity: .25;
        pointer-events: none;
      }

    }

  }
}
