.c-header {
  border-bottom: 1px solid $clr-secondary;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1023px) {
    height: 60px;
    max-height: 60px;
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 650px) {
      display: none;
    }

    button {
      border: 1px solid $clr-secondary;
      background-color: $clr-secondary;
      @include square-dimensions(3rem);
      border-radius: .5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        transform: rotate(90deg);
        font-size: .8rem;
      }

      &:last-of-type {
        i {
          transform: rotate(-90deg);
        }
      }
    }
    span {
      margin: 0 1rem;
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;
      small {
        font-weight: 400;
        font-size: 1.2rem;
      }
    }
  }

  &__logo {
    display: flex;

    img {
      max-width: 20rem;
      max-height: 5rem;

      @media screen and (max-width: 1023px) {
        max-width: 18rem;
      }

      @media screen and (max-width: 450px) {
        max-width: 13rem;
        max-height: 4rem;
      }
    }

  }

  &__nav {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1023px) {
      display: none;
    }

    li {
      margin: 0 1rem;
      a {
        font-size: 1.7rem;
        font-weight: 600;
      }
    }
  }
  &__misc {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1023px) {
      display: none;
    }

    button {
      margin-left: .5rem;

      i {
        font-size: 2rem;
      }
    }
    .btn--outline {
      span, i {
        color: $color-black !important;
      }
    }
    .btn--square {
      span, i, i:before {
        color: $color-black !important;
      }
    }
  }

  &__menu-trigger {
    width: 35px;
    height: 40px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 1023px) {
      display: flex;
    }

    span {
      width: 100%;
      height: 4px;
      background-color: $clr-primary;

      &:nth-of-type(2) {
        margin: 4px 0;
      }
    }
  }

  &__bundle {
    display: flex;
    align-items: center;
  }

  &__switch {
    margin-left: 1.5rem;
    height: 100%;
    display: flex;
    align-items: center;

    span {
      margin-right: 1rem;
      color: #000000 !important;
    }
    i {
      font-size: .8rem;
    }
  }

  &--tasks {

  }

  &__mobile-date {
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    display: none;

    @media screen and (max-width: 650px) {
      display: flex;

      .c-header__date {
        display: flex;
      }
    }
  }

}
