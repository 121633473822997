.c-table-standard {
  width: 100%;
  min-width: 65rem;
  overflow-y: scroll;
  td {
    font-size: 1.6rem;

    a {
      text-decoration: underline;
    }
  }
  tr {
    border-bottom: 1px solid $gray-new;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &--prices {
    margin-bottom: 1rem;
    tr {
      td:last-of-type {
        text-align: right;
      }
    }
  }

  .noScan {
    .icon-check-circle {
      opacity: .3;
      &:before {
        color: gray;
      }
    }
  }
  &--small {
    th {
      font-size: 1.4rem;
    }
    td {
      font-size: 1.4rem;
    }
  }
}
