.c-plus-minus {
  display: flex;
  align-items: center;
  button {
    @include square-dimensions(3rem);
    border-radius: 50%;
    background-color: $clr-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    i {
      line-height: 1;
      &:before {
        color: $clr-secondary;
      }
      font-weight: 600;
      font-size: 1rem;
    }
    &:nth-of-type(1) {
      i {
        transform: rotate(180deg);
      }
    }
  }
  small {
    font-size: 1.7rem;
    margin: 0 1rem;
    width: 1.75rem;
    min-width: 1.75rem;
    text-align: center;
    font-weight: 600;
  }
}
