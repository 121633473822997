.c-tabs {
  border-bottom: 1px solid $clr-secondary;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  &__navigation {
    display: flex;

    @media screen and (max-width: 600px) {
      order: 2;
      width: 100%;
      justify-content: center;
    }

    &__single {
      display: flex;
      align-items: center;
      padding: 1.25rem 1.5rem;

      @media screen and (max-width: 600px) {
        width: calc(100% / 3);
        justify-content: center;
      }

      span {
        font-size: 1.5rem;
        margin-right: .5rem;
        opacity: .5;

        @media screen and (max-width: 600px) {
          font-size: 1.4rem;
        }
        @media screen and (max-width: 450px) {
          font-size: 1.2rem;
        }
        @media screen and (max-width: 400px) {
          font-size: 1.1rem;
        }
      }
      div {
        border-radius: 50%;
        background-color: $clr-secondary;
        @include square-dimensions(1.8rem);
        display: flex;
        align-items: center;
        justify-content: center;
        small {
          color: black;
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }
    .isActive {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        margin: auto;
        width: calc(100% - 3rem);
        height: 2px;
        background-color: $clr-primary;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
      span {
        opacity: 1;
        font-weight: 600;
      }
    }
    &--large {
      .c-tabs__navigation__single {
        padding: 1.5rem;

        @media screen and (max-width: 600px) {
          width: 50%;
        }
        span {
          font-size: 1.8rem;
        }
      }
    }
  }
  .c-form__select i {
    top: 1.5rem;
  }
  .c-form__select select {
    height: 3.5rem;
    border-radius: .4rem;
    font-size: 1.4rem !important;
  }
  .c-form__select {
    @media screen and (max-width: 600px) {
      max-width: 14rem;
      margin-top: 1rem;
      width: 100%;
      max-width: 100%;
    }
  }
}
