.c-pagination {

  &--split {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: flex-end;

      .c-pagination__results {
        margin-top: 1.5rem;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;

    .previous, .next {
      display: none;
    }

    a {
      @include square-dimensions(4rem);
      border-radius: .6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 1.5rem;
      font-family: 'Avenir-Medium', sans-serif;
      margin-left: 1rem;
      border: 1px solid $clr-secondary;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-left: 1rem;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
      }


    }

    li {

      &:hover {
        cursor: pointer;
      }

      &.isActive {
        a {
          border: 1px solid $clr-primary;
        }
      }
    }

    small {
      font-size: 1.5rem;
      font-family: 'Avenir-Medium', sans-serif;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-left: 1rem;
      }
    }


  }

  &__results {
    display: flex;
    align-items: center;

    label {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  }
}
