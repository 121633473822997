.c-tag {
  @include square-dimensions(2.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $clr-primary;
  border-radius: 50%;
  text-align: center;

  small {
    font-size: 1.4rem;
    font-weight: 600;
    color: $clr-secondary;
  }

  &--black {
    background-color: black;
  }
}
