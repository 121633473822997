// VDL Color Scheme

$primary-color: #002BD3;
$secondary-color: #EEEEEE;
$tertiary-color: #FF7700;
$quaternary-color: #93D23F;

// CORE STYLING

body {
  color: $color-black;
}

span {
  a {
    color: $color-black;
  }
}

.c-form__row {

  select {
    &:focus {
      border: 1px solid $tertiary-color;
    }
  }

  input[type=email], input[type=text], input[type=number], textarea {
    border: 1px solid $secondary-color;

    &:focus {
      border: 1px solid $tertiary-color;
    }
  }
}

// COMPONENTS

.c-header__usp {
  background-color: $primary-color;

  span, i {
    color: $color-white;
  }
}

.c-date-picker__info {
  border-bottom: 1px solid $secondary-color;
}

.c-date-picker__button {
  border: none;
  background-color: $tertiary-color;
  span {
    color: $color-white;
  }
}

.c-lang-selector {
  select {
    border-color: $secondary-color;
    color: $primary-color;
  }
  i {
    color: $color-black;
  }
}

.c-subject-bar__single {
  background-color: $secondary-color;
  &__number {
    background-color: $color-white;
    small {
      color: $primary-color;
    }
  }
  h2 {
    color: $color-black;
  }
  &--active {
    background-color: $primary-color;

    h2 {
      color: $color-white;
    }
  }
}

.c-summary {
  border-color: $secondary-color;

  .c-summary__subject {
    border-bottom-color: $secondary-color;
  }

  .c-summary--period__text span, .c-summary__edit {
    color: $color-black;
  }

  .c-summary__edit {
    @media screen and (max-width: 600px) {
      background-color: $secondary-color;
    }
  }

  .btn--pill {
    background-color: $primary-color;

    span {
      color: $color-white;
    }
  }
}

.c-summary--totals {
  span, h5, a, button {
    color: $color-black;
  }
}

.c-delivery-options__single, .c-delivery-options__check, .c-delivery-options__store__select-store {
  border-color: $secondary-color !important;
}

.c-delivery-options__store__options__single {
  background-color: lighten($secondary-color, 5%);
}

.c-delivery-options__store__options__single__number {
  border-color: $primary-color;
}

.c-delivery-options__store__options__single--selected {
  border: 2px solid $primary-color !important;
  background-color: $color-white;

  .c-delivery-options__store__options__single__number {
    background-color: $primary-color;

    span {
      color: $color-white;
    }
  }
}

.c-form__select {
  select {
    border: 1px solid $secondary-color;
  }
}

.c-products__single {
  border: 2px solid $secondary-color;
}

.c-products__single__media {
  border: 1px solid $secondary-color;
}

.c-products__single__modal-trigger {
  border: 1px solid $secondary-color;

  i:before {
    color: $color-black;
  }
}

.c-products__single__amount button {
  background-color: $primary-color;

  i:before {
    color: $color-white;
  }
}

.c-products__single__locations__single {
  border-top: 1px solid $secondary-color;
}

.c-products__single__locations__single__wrapper {
  button {
    border: 1px solid $primary-color;

    i:before {
      color: $primary-color;
    }
  }
}

.c-customer-info {
  button {
    border: none;
    background-color: $tertiary-color;
    span {
      color: $color-white;
    }
  }
}

.c-order-overview__box {
  border: 1px solid $secondary-color;

  header {
    border-bottom: 1px solid $secondary-color;
  }
}

.c-order-overview__products {
  header{
    border-bottom: 1px solid $secondary-color;
  }
}

.c-order-overview__products__single__media {
  border: 1px solid $secondary-color;
}

.c-order-overview__discount {
  input {
    border: 1px solid $secondary-color;
  }
  button {
    color: $tertiary-color;
  }
}

.c-order-overview__terms {
  border-top: 1px solid $secondary-color;
  border-bottom: 1px solid $secondary-color;
}

.c-order-overview__payment-button {
  button {
    border: none;
    background-color: $tertiary-color;

    span {
      color: $color-white;
    }
  }
}

.c-select-search__results {
  border: 1px solid $secondary-color;
}
.c-select-search__results__single {
  border-bottom: 1px solid $secondary-color !important;
  background-color: lighten($secondary-color, 6%);

  &--standard {
    span {
      color: $tertiary-color;
    }
  }
}

.c-modal__bg {
  background-color: rgba(0, 43, 109, .85);
}
.c-modal__close {
  background-color: $secondary-color;
}

.c-modal__header {
  i {
    &:before {
      color: $primary-color;
    }
  }
}

.c-modal__content {
  .c-products__single__locations {
    border: 1px solid $secondary-color;
  }
}

.c-modal__locations__single {
  border-top: 1px solid $secondary-color;
}

.c-modal__locations__single__number {
  border: 2px solid $primary-color;

  span {
    color: $color-black;
  }
}

.c-sticky-footer {
  border-top: 1px solid $secondary-color;

  button {
    border: none;
    background-color: $tertiary-color;
    span {
      color: $color-white;
    }
  }
}
