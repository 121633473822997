.c-task {
  .c-task-information {
    margin-bottom: 2rem;
  }
  .c-accordion {
    margin-bottom: 2rem;
  }
  &--m-t {
    margin-top: 2rem;
  }
}

.c-task-information {
  display: flex;
  border: 1px solid $gray-new;
  border-radius: .6rem;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  &__map {
    width: 30rem;
    min-width: 30rem;
    min-height: 30rem;
    background-color: lightblue;
    border-radius: .6rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    @media screen and (max-width: 900px) {
      width: 100%;
      min-width: 100%;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    width: 100%;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 2rem;
      h3 {
        margin: 0;
        font-size: 2rem;
        small {
          font-size: 1.3rem;
          font-weight: 300;
        }
      }
      span {
        font-size: 1.7rem;
        strong {

        }
      }
    }
    &__wrap {
      border-top: 1px solid $gray-new;
      display: flex;
      height: 100%;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }
    &__primary {
      border-right: 1px solid $gray-new;
      padding: 1.5rem 2rem;
      width: 100%;
      div {
        display: flex;
        flex-direction: column;
        margin-bottom: .75rem;

        .c-tag {
          margin-bottom: 0;
          margin-right: .75rem;
        }

        span {
          display: flex;
          align-items: center;
          font-size: 1.6rem;
          strong {
            font-weight: 600;
          }
        }
      }
    }
    aside {
      padding: 1.5rem 5rem 1.5rem 1.5rem;

      @media screen and (max-width: 600px) {
        padding: 1.5rem;
        border-top: 1px solid $gray-new;
      }

      div {
        display: flex;
        flex-direction: column;
        margin-bottom: .75rem;
        span {
          white-space: nowrap;
          font-size: 1.6rem;
          strong {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.c-task-scan {
  border: 1px solid $gray-new;
  border-radius: .6rem;
  margin-bottom: 2rem;

  header {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid $gray-new;


    h2,h3,h4,h5,h6 {
      font-size: 2rem;
      display: flex;
      align-items: center;
      margin-bottom: 0;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }
      small {
        font-size: 1.3rem;
        margin-left: .5rem;
        font-weight: 300;

        @media screen and (max-width: 600px) {
          margin: .5rem 0;
        }
      }
    }
    ul {

      li {
        span {
          font-size: 1.3rem;
        }
      }
    }
  }
  &__content {
    padding: 1.5rem 2rem;
    overflow: auto;

    .c-table-standard {
      tr {
        td {
          background-color: lighten($green-primary, 35%);
        }
      }
      .icon-check-circle {

      }
      .noScan {
        td {
          background-color: transparent;
        }
      }
    }

    &--bulk {
      .c-table-standard {
        tr {
          td {
            background-color: transparent;
          }
        }
      }
    }

    &__bundle {
      padding: 1rem;
      border: 1px solid $gray-new;
      border-radius: .6rem;
      margin-bottom: 1rem;
      overflow: auto;

      &:last-of-type {
        margin-bottom: 0;
      }
      header {
        padding: 0;

        span {
          font-weight: 600;
        }
      }
    }
  }
}

.c-task-appoint {
  border: 1px solid $gray-new;
  border-radius: .6rem;
  margin-bottom: 2rem;

  header {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid $gray-new;

    h2, h3, h4, h5, h6 {
      font-size: 2rem;
      display: flex;
      align-items: center;
      margin-bottom: 0;

      small {
        font-size: 1.3rem;
        margin-left: .5rem;
        font-weight: 300;
      }
    }
  }
  &__content {
    padding: 1.5rem 2rem;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 850px) {
      flex-direction: column;
      padding: 0;
    }
  }
  &__single {
    width: calc(100% / 3 - 1rem);
    padding-right: 1rem;
    margin: .5rem;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;

    @media screen and (max-width: 850px) {
      width: 100%;
      padding: 1rem;
      border-bottom: 1px solid $gray-new;

      &:last-of-type {
        border-bottom: none;
      }
    }

    span {
      opacity: .5;
    }

    .c-tasks__single__select {
      display: flex;
    }
  }
  .isAppointed {
    .c-tasks__single__select {
      div {
        border: 1px solid $clr-primary;
      }
      i {
        opacity: 1;
      }
      small {
        display: none;
      }
    }
    span {
      opacity: 1;
      font-weight: 600;
    }
  }
}
