.c-bundles {
  &__wrap {

  }
  &__single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: 1px solid $clr-secondary;
    border-radius: .5rem;
    margin-bottom: 1rem;
    position: relative;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &__info {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }

      h2 {
        font-size: 1.7rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
        margin-right: 1.5rem;
        padding-right: 1.5rem;
        border-right: 1px solid $clr-secondary;

        @media screen and (max-width: 768px) {
          border-right: none;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-right: 0;
          padding-right: 0;
        }

        a {
          font-size: 1.3rem;
          font-weight: 400;
          text-decoration: underline;
        }
      }
      div {
        display: flex;

        @media screen and (max-width: 900px) {
          flex-direction: column;
        }
        span {
          font-size: 1.5rem;
          margin-right: 1rem;
          strong {
            font-weight: 600;
          }
        }
      }
    }
    &__cta {
      display: flex;
      align-items: center;

      @media screen and (max-width: 600px) {
        width: 100%;
        justify-content: space-between;
      }

      small {
        font-size: 1.3rem;
      }
      a {
        margin-left: 1rem;
      }
    }
  }
}
