// bower:scss
// endbower

@import '0.defaults/defaults';
@import '1.objects/objects';
@import '2.components/components';
@import '3.utilities/utilities';
@import '4.themes/themes';
@import '5.scope/scope';
@import '6.is-has/is-has';
@import '7.hacks/hacks';

.c-tabs__navigation__single {
  &:hover {
    cursor: pointer;
  }
}

button:disabled {
  opacity: .5;
}

.c-loader {

  span{
    font-size: 15px
  }

}

body{
  padding-bottom: 5rem;
}

.isDisabled{
  opacity: .2;
  pointer-events: none;
}

.c-tasks__single__info{
  > * {
    margin-right: 1rem;
  }
}