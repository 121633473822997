.c-accordion {
  border: 1px solid $gray-new;
  border-radius: .6rem;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    h3 {
      font-size: 2rem;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      small {
        font-size: 1.3rem;
        margin-left: .5rem;
        font-weight: 300;
      }
    }
  }
  &__content {
    border-top: 1px solid $gray-new;
    padding: 1.5rem 2rem;
    display: none;
    overflow: auto;

    div {
      display: flex;
      span {
        width: 50%;
        strong {
          padding-right: 1rem;
          font-weight: 600;
        }
      }
    }
  }

  &--small {
    header {
      padding: 1rem 1.5rem;

      h3 {
        font-size: 1.7rem;

        small {
          font-size: 1.2rem;
        }
      }
      i {
        font-size: .9rem;
      }
    }
    .c-accordion__content {
      padding: 1rem 1.5rem;
      .c-table-standard {
        td {
          font-size: 1.4rem;
        }
      }
    }
  }
}
.accordionActive {
  header {
    i {
      transform: rotate(180deg);
    }
  }
  .c-accordion__content {
    display: block;
  }
}
