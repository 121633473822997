.c-order-handling {
  padding-bottom: 15rem;
  &__number {
    @include square-dimensions(4rem);
    background-color: $clr-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: .5rem;
    position: relative;

    @media screen and (max-width: 650px) {
      @include square-dimensions(3.5rem);
    }

    span {
      line-height: 1;
      font-size: 2rem;
      font-weight: 600;
      color: $clr-secondary;
    }
    &__small {
      @include square-dimensions(3rem);

      @media screen and (max-width: 650px) {
        @include square-dimensions(2.5rem);
      }

      span {
        font-size: 1.6rem;

        @media screen and (max-width: 650px) {
          font-size: 1.5rem;
        }
      }
    }

    &__warning {
      background-color: $orange-primary;
    }
    &__error {
      background-color: $red-primary;
    }

    &--check {
      background-color: $green-primary;
    }

    div {
      position: absolute;
      top: -1rem;
      right: -1rem;
      @include square-dimensions(2rem);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: .9rem;
        &:before {
          color: $color-white;
        }
      }

      &:nth-of-type(2) {
        top: 1.25rem;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;

    h2 {
      margin: 0 1.5rem 0 1rem;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    &__single {
      width: calc(50% - .5rem);
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $clr-secondary;
      border-radius: .5rem;
      padding: 1rem 1rem 1rem 1.5rem;

      @media screen and (max-width: 650px) {
        width: 100%;
        padding: .5rem 1rem;
        margin-bottom: .5rem;
      }

      &__wrap {
        display: flex;
        align-items: center;
      }

      &__amount {
        min-width: 4rem;
        width: 4rem;
        span {
          font-weight: 600;
        }
      }
      h6 {
        margin: 0 2rem 0 0;
        font-weight: 500;
        font-size: 1.5rem;
      }
      .c-order-handling__number {

      }
    }
  }

  &__scanned {
    margin-bottom: 2.5rem;
    header {
      border-bottom: 1px solid $clr-secondary;
      h4 {
      }
    }
  }
  &__comment {
    figure {
      width: 100%;
      display: flex;
      height: 1px;
      margin: 1rem 0;
      background-color: $clr-secondary;
    }

    .c-form__row {
      margin-bottom: 1rem;
    }
  }

  &__text {
    margin-bottom: 2.5rem;
  }

  .c-totals {
    margin-bottom: 2.5rem;
  }

  &__action-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $clr-secondary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    width: 100%;

    @media screen and (max-width: 650px) {
      padding: 1rem 1rem 3rem 1rem;
    }

    &__status {
      display: flex;
      align-items: center;

      @media screen and (max-width: 650px) {
        span {
          display: none;
        }
      }

      .c-order-handling__number {
        margin: 0 1rem;

        @media screen and (max-width: 650px) {
          span {
            display: block;
          }
        }
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
    }
  }


}
