.c-notification {
  display: flex;
  align-items: center;
  background-color: $clr-primary;
  padding: 1rem 1rem 1rem 1.5rem;
  margin: .5rem 0;
  border-radius: .5rem;
  position: relative;

  span {
    color: $color-white;
    font-size: 1.6rem;
    padding-right: 1.5rem;
    line-height: 1.3;
  }

  i {
    font-size: 2rem;
    margin-right: 1rem;

    &:before {
      color: $color-white;
    }
  }

  &__close {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    i {
      font-size: 1.7rem;
      margin: 0;
    }
  }

  &--error {
    background-color: $red-primary;
  }

  &--success {
    background-color: $green-primary;
  }

  &--fixed {
    position: fixed;
    left: 30px;
    z-index: 2;
    max-width: 650px;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.35);
    padding: 1rem 50px 1rem 1.5rem;
    transition: all ease-in-out .5s;
    opacity: 1;
    bottom: 80px;

    &.exit {
      bottom: 0;
      opacity: 0
    }

    @media screen and (max-width: 650px) {
      bottom: 15px;
      left: 15px;
      max-width: calc(100% - 30px);
      width: calc(100% - 30px);
    }

    &:nth-of-type(2) {
      transform: translateY(-125%);
    }

    &:nth-of-type(3) {
      transform: translateY(-250%);
    }

    &:nth-of-type(4) {
      transform: translateY(-375%);
    }

    &:nth-of-type(5) {
      transform: translateY(-500%);
    }

  }
}
