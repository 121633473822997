.c-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $clr-primary;
    opacity: .95;
  }

  &__close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    @include square-dimensions(4rem);
    background-color: $color-white;
    border: none;
    border-radius: .6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 1.8rem;
      &:after {
        color: $clr-primary;
      }
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    p {
      font-size: 4rem;
      font-weight: 300;
      opacity: .75;
      color: $color-white;
      display: flex;
      padding: 1rem 0;
      margin: 1rem 0;

      &:hover{
        cursor: pointer;
      }

      @media screen and (max-width: 600px) {
        font-size: 3rem;
      }
    }
    .isActive {
      font-weight: 700;
      opacity: 1;
      text-decoration: underline;
    }
  }
}
