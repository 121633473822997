.c-subheader {
  border-bottom: 1px solid $clr-secondary;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  &__tabs {
    height: 6rem;
    min-height: 6rem;
    display: flex;
    align-items: center;

    a {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2rem;
      span {

      }
      small {

      }
    }
    .isActive {
      &:before {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 2px;
        background-color: $clr-primary;
      }
    }
  }
  &__misc {
    display: flex;

    @media screen and (max-width: 600px) {
      margin-top: 1rem;
    }

    a, button {
      margin-left: .5rem;
    }
    .btn--outline {
      span {
        color: #000000 !important;
      }
    }
  }
  &__task {
    h1 {
      font-size: 2rem;
      margin: 0;

      small {
        font-size: 1.5rem;
        font-weight: 300;

      }
    }
  }

  &--detail {
    padding: 1rem 2rem;
  }
}
