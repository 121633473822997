// Font name
// @font-face {
//   font-family: 'Fontname-Style';
//   src: url("../fonts/fjalla-one/FjallaOne-Regular.ttf") format('truetype');
// }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.ttf?elcaxd") format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e90b";
}
.icon-calendar:before {
  content: "\e90c";
}
.icon-home:before {
  content: "\e90d";
}
.icon-lock:before {
  content: "\e90e";
}
.icon-power:before {
  content: "\e90f";
}
.icon-scan:before {
  content: "\e910";
}
.icon-search:before {
  content: "\e911";
}
.icon-swap:before {
  content: "\e912";
}
.icon-edit:before {
  content: "\e90a";
  color: #002b6d;
}
.icon-arrow-down:before {
  content: "\e900";
  color: #002b6d;
}
.icon-arrow-right:before {
  content: "\e901";
  color: #002b6d;
}
.icon-bicycle:before {
  content: "\e902";
  color: white;
}
.icon-check-circle:before {
  content: "\e903";
  color: #93d23f;
}
.icon-check:before {
  content: "\e904";
  color: #f1f1f1;
}
.icon-cross:before {
  content: "\e905";
  color: #002b6d;
}
.icon-info:before {
  content: "\e906";
  color: #ccd5e2;
}
.icon-minus:before {
  content: "\e907";
  color: #002b6d;
}
.icon-pin:before {
  content: "\e908";
  color: #002b6d;
}
.icon-plus:before {
  content: "\e909";
  color: #002b6d;
}
