.u-bg-cover-center {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.u-bg-contain-top {
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}

.u-bg-divider {
  width: 100%;
  height: 1px;
  background-color: $secondary-color;
  margin: 1rem 0;
}
