.c-icon-blocks {
  display: flex;
  align-items: center;

  @media screen and (max-width: 650px) {
    flex-wrap: wrap;
  }

  &__single {
    width: 20%;
    min-height: 12rem;
    height: 12rem;
    margin-right: 1rem;
    position: relative;
    border: none;
    background-color: $clr-primary;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 650px) {
      width: calc(50% - .5rem);
      margin-bottom: 1rem;

      &:nth-of-type(even) {
        margin-right: 0;
      }
    }

    i {
      color: $color-white;
      font-size: 4rem;
      line-height: 1;
      margin-bottom: 1rem;

      @media screen and (max-width: 1000px) {
        font-size: 3.5rem;
      }
    }
    h2 {
      font-size: 1.8rem;
      color: $color-white;
      margin: 0;

      @media screen and (max-width: 1000px) {
        font-size: 1.8rem;
      }
    }
    div {
      position: absolute;
      top: 0;
      right: 0;
      width: 2.5rem;
      height: 2.5rem;
      min-width: 2.5rem;
      min-height: 2.5rem;
      background-color: $clr-secondary;
      border-bottom-left-radius: .5rem;

      small {
        font-size: 1.4rem;
        color: $clr-primary;
        font-weight: 600;
      }
    }
  }
}
