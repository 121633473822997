.c-totals {
  &__options {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 850px){
      flex-direction: column;
      justify-content: flex-start;
    }

    &__half {
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-start;

      @media screen and (max-width: 850px){
        &:first-of-type {
          margin-bottom: 1rem;
        }
        input[type='text']{
          text-align: right;
          max-width: 20rem;
        }
        .c-form__group--row {
          justify-content: space-between;
        }
      }

      > div {
        margin-bottom: 1rem !important;

        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }

      .c-form__row {
        margin: 0;
      }

      .c-form__group {
        label {
          white-space: nowrap;
          margin-right: 1rem;
          font-size: 1.6rem;
        }
      }

      &:first-of-type {
        .c-totals__sum {
          span:first-of-type {
            margin-right: 2rem;
          }
        }
      }
      &:last-of-type {
        .c-totals__sum {
          span:first-of-type {
            margin-right: 2rem;
          }
        }
      }
    }
  }
  &__sum {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 1.6rem;
    }
  }
}
